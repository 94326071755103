.card {
  margin-top: 10px;
  padding: 5px;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: space-between;

  img {
    width: 50px;
    margin-right: 10px;
  }

  .value {
    font-size: 18px;
    font-weight: bold;
    margin: 0 20px;
  }

  .code {
    font-size: 18px;
    font-weight: bold;
    margin-right: 10px;
    cursor: pointer;
  }

  .feedback {
    display: flex;
    position: relative;
    .message {
      width: 70px;
      height: 30px;
      z-index: 100;
      margin: auto;
      position: absolute;
      background-color: black;
      color: #fff;
      border-radius: 6px;
      padding: 5px 0;
      text-align: center;
      right: 35%;
      bottom: 1px;
      top: 5px;
      opacity: 0.6;
    }
    .message::after {
      content: "";
      position: absolute;
      border-width: 5px;
      margin-left: -5px;
      border-style: solid;
      border-color: transparent transparent black transparent;
      right: 30px;
      bottom: 30px;
    }
  }
  .feedback-amazon {
    display: flex;
    position: relative;
    .message {
      width: 70px;
      height: 30px;
      z-index: 100;
      margin: auto;
      position: absolute;
      background-color: black;
      color: #fff;
      border-radius: 6px;
      padding: 5px 0;
      text-align: center;
      right: 25%;
      bottom: 1px;
      top: 5px;
      opacity: 0.6;
    }
    .message::after {
      content: "";
      position: absolute;
      border-width: 5px;
      margin-left: -5px;
      border-style: solid;
      border-color: transparent transparent black transparent;
      right: 30px;
      bottom: 30px;
    }
  }

  .date {
    font-size: 14px;
    font-weight: bold;
    margin-right: 10px;
  }
}

.card-details {
  font-size: 16px;
  font-weight: bold;
  background: #f7f7f7;
  border-radius: 4px;
  padding: 20px;
  margin-bottom: 30px;

  .card-balance {
    text-transform: uppercase;
  }
}

.voucher-code {
  font-size: 20px;
  font-weight: bold;
  border: 1px dashed #ddd;
  background: #f7f7f7;
  text-align: center;
  border-radius: 4px;
  padding: 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  .amazon-image {
    width: 70px;
    height: 70px;
    align-self: flex-start;
  }
}

.debit-vouchercopy {
  display: flex;
  position: relative;

  .message {
    position: absolute;
    width: 70px;
    height: 30px;
    z-index: 100;
    margin: auto;
    position: absolute;
    background-color: black;
    color: #fff;
    border-radius: 6px;
    padding: 5px 0;
    text-align: center;
    opacity: 0.7;
    left: 35px;
    bottom: -35px;
    margin-top: 25px;
  }

  .message::after {
    content: "";
    position: absolute;
    border-width: 5px;
    margin-left: -5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
    right: 30px;
    bottom: 30px;
  }
}

.feedback-homepage {
  display: flex;
  position: relative;
  justify-content: center;

  .message-homepage {
    position: absolute;
    width: 70px;
    height: 30px;
    z-index: 100;
    margin: auto;
    position: absolute;
    background-color: black;
    color: #fff;
    border-radius: 6px;
    padding: 5px 0;
    text-align: center;
    opacity: 0.7;
    // left: 310px;
    bottom: 6px;
    margin-top: 25px;
  }

  .message-homepage::after {
    content: "";
    position: absolute;
    border-width: 5px;
    margin-left: -5px;
    border-style: solid;
    border-color: transparent transparent black transparent;
    right: 30px;
    bottom: 30px;
  }
}
