/* Variables */
$vin-blue: #5bb7db;
$vin-green: #60b044;
$vin-red: #ff0000;

/* Styles */
body {
  font: 14px "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1.4em;
  color: #4d4d4d;
  margin: 0 auto;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  font-smoothing: antialiased;
  font-weight: 300;
}

td {
  padding: 12px;
}

/* Global form */

.form-group {
  .form-label {
    font-weight: bold;
  }
}

.card-body {
  padding: 0.25rem;
}

.card-text {
  align-items: center;
}

.inputSelect {
  display: flex;
  flex-direction: row;
  font-weight: bold;
  text-transform: uppercase;
  align-content: center;
  justify-content: flex-start;
  font-size: 22px;

  .form-check {
    padding: 0px;
    align-self: center;

    input {
      visibility: hidden;
    }

    label {
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 20px;
      justify-content: center;
      text-align: center;
      border-radius: 9px;
    }

    input:checked + label {
      background: rgba(255, 0, 0, 0.8);
      color: white;
    }
  }
}

.react-datepicker-wrapper {
  display: block !important;
}

.savings {
  color: $vin-green;
}
.loss {
  color: $vin-red;
}
input.small {
  width: 46px;
}

.add-paymentlink-btn {
  color: #fff;
}

.add-paymentlink-btn:hover {
  color: #fff;
}
