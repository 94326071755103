.resellerCard {
  border-radius: 4px !important;
  box-shadow: 0 0 5px 1px rgba(8, 11, 14, 0.06),
    0 5px 5px -1px rgba(8, 11, 14, 0.1);
}

.element-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding-bottom: 1rem;
  margin-bottom: 2rem;
  position: relative;
  z-index: 1;
  margin-top: 20px;
  text-align: left;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: bold;
}

// .element-header::after {
//   content: "";
//   background-color: #fe4f38;
//   width: 25px;
//   height: 4px;
//   border-radius: 0px;
//   display: block;
//   position: absolute;
//   bottom: -3px;
//   left: 0px;
// }

.addMoney-btn {
  padding: 10px;
  background-color: #fe4f38;
  color: #fff !important;
  border-radius: 5px;

  a {
    color: #fff !important;
  }
}
